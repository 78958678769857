.title {
    display: flex;
    justify-content: center;
    
    height: 11.75vh;
    align-items: flex-end;
    margin-bottom: 3vh;
    padding: 0px 10.68vw 0px 7.5vw;
}

.title.mobile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 10vh;
    margin-bottom: 2vh;
    padding: 0px 7.5vw 0px 7.5vw;
}

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;

}

.containerchild {
    display: flex;
    align-items: center;
    justify-content: space-between;
    

}

.container h1 {
    color: #17262a;  
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    margin: 0px;
    font-weight: normal;
}

.container h2 {
    color: #d3dbde;
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    padding: 0%;
    margin: 0px;
}

.container h3 {
    color: #a6a09e;
    font-size: clamp(8px, calc(8px + (12 - 8) * ((100vw - 1366px) / (1920 - 1366))), 12px);
    background: transparent;
    font-family: 'M PLUS 1', sans-serif;
}

.buttonMap {
    background: transparent;
    border: none;
    height: 11.6vh;
    padding: 0px;
    cursor: pointer;
    display: flex;
    align-items: end;
}

.buttonMap:hover {
    background-color: transparent; /* Mantiene el mismo color en hover */
  }

.ico {
    height: 2vh;
    margin-left: 1vw;
}
.fecha {
    font-size: clamp(6px, calc(6px + (8 - 6) * ((100vw - 450px) / (450 - 320))), 8px);
}
