.container  {
    background: #EDF0F2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #FFF;
    width: 8.23vw;
    flex-shrink: 0;
    filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.10));
    min-width: 105px;
    height: 65vh;
}

.container h2 {
    color: #fff; 
    padding: 20px
}

.container h3 {
    border-radius: 8px 8px 0px 0px;
    background: #344955;
    color: #FFF;
    text-align: center;
    height: 2.87vh;
    font-size: 1.3vh;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.container h4 {
    color: #90A4AE;
    text-align: center;
    padding-top: 0.89vh;
    font-size: 1.2vh;
    font-weight: 700;
    line-height: normal;
}

.tituloValue {
    color: #90A4AE;
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 400;
    font-size: 1.4vh;
    text-align: center;
}

.desired {
    color: #344955;
    text-align: center;
    font-size: 2.5vh;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.current {
    color: #344955;
    text-align: center;
    font-size: 3.2vh;
    font-style: normal;
    line-height: normal;
    padding-bottom: 0.6vh;
    height: 4vh;
}

.currentP {
    color: #344955;
    text-align: center;
    font-size: 3.2vh;
    font-style: normal;
    line-height: normal;
    padding-bottom: 0.6vh;
    
}

.desiredP {
    color: #344955;
    text-align: center;
    font-size: 1.5vh;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
    padding-bottom: 0.83vh;
}

table {
    align-self: center;
    align-items: center;
    width: 100%;
}

th {
    color: #90A4AE;
    text-align: center;
    padding-top: 0.9vh;
    font-size: 1.2vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    

}
tr {
    display: flex;
    justify-content: space-around;
    
}
td {
    color: #344955;
    text-align: center;

    font-size: 1.8vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.divition {
    background: rgba(144, 164, 174, 0.40);
    height: 1px;
    /* width: 100px; */
}

.table {
    display: flex;
      align-items: center;
      justify-content: space-around;
}

.table2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.indicators {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 10vh;
    align-items: center;

}

@media screen and (max-width: 450px) {
    .container h3 {
        border-radius: 8px 8px 0px 0px;
        background: #34495582;
        color: #FFF;
        text-align: center;
        height: 2.87vh;
        font-size: 1.3vh;
        font-weight: 700;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        }

        .container  {
            background: #EDF0F2;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            background: #FFF;
            width: 8.23vw;
            flex-shrink: 0;
            filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.10));
            min-width: 105px;
            height: auto;
        }
  }