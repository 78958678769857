.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 0px 0px 1.3vw;
    height: 4.62vh;
}

.value {
    color: #344955;
    font-size: clamp(30px, calc(30px + (36 - 30) * ((100vw - 1366px) / (1920 - 1366))), 36px);
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    align-self: baseline;
}

.unity {
    color: #344955;
    font-size: clamp(11px, calc(11px + (15 - 11) * ((100vw - 1366px) / (1920 - 1366))), 15px);
    font-weight: 400;
    line-height: normal;
    align-self: flex-end;
    align-self: baseline;
    padding-left: 0.9375vw;
}

@media screen and (max-width: 450px) {
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0px 0px 0px 1.3vw;
        height: auto;
    }
    
    .value {
        color: #344955;
        font-size: clamp(16px, calc(6.22222px + 1.48148vh), 20px);
        font-weight: 400;
        line-height: normal;
        text-align: justify;
        align-self: baseline;
    }
    
    .unity {
        color: #344955;
        font-size: 6px;
        font-weight: 400;
        line-height: normal;
        align-self: flex-end;
        align-self: baseline;
        padding-left: 0.9375vw;
    }
}