.bodyf {
  flex: 1;  
  display: flex;
    flex-direction: column;
    min-height: 100vh; /* Establece el mínimo alto del cuerpo al 100% de la altura visible */
    width: 100%;
    background-color: #EDF0F2
  } 
  
  
  .mainContent {
    flex: 1; /* Hace que el contenido principal ocupe todo el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center;

  }
  