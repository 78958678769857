.container {
    width: 92%;
    padding-left: 8%;
    border-radius: 7px; 
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    height: 20vh
}

.rec {
    background: #d3cfce;
    border-radius: 1px;
    width: 3.5vw;
    height: 2px;
    margin: 2.5vh 0px 0px 0px;
}

.containerchild{
    display: flex;
    width:  85%;
    flex-direction: column;
    margin-left: 8vw;
    margin-bottom: 2.5vh;
    justify-content: flex-end;
}

.title {
    margin: 0px;
    font-weight: 600;
    font-size: 7px;
    margin-top: 3vh;
}

.containerLub {
    margin-top: 8vh;
    margin-left: 1vw;
}

.containerLub a {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    font-weight: 500;
    color: #585858;
    margin-bottom: 3.7vh;
}

.containerLubchild {
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
    width: 75%;
}

.containerLubchild2 {
    display: flex;
    justify-content: space-between;
    
}

.recLub {
    height: 1px;
    width: 1vw;
    margin-top: 1vh;
    background-color: #d9d9d9;
 }

 .containerchild h1{
    margin-top: 0px;
}

