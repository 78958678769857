.container {
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-width: 20vw;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 3vh 1vw;
}

.container.mobile {
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-width: 20vw;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 3vh 5vw;
    width: 55vw;
}

.card {
    border-radius: 5px;
    width: 90%;
    display: flex;
   flex-direction: column;
}

.card label {
    font-size: 1.4vh;
    margin-bottom: 0.7vh;
    color: slategray;
}

.field {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: auto;
}

.title {
    color: #475059 ;
    font-weight: 700;
    text-align: center;
    font-size: 3vh;
    margin: 2vh 0px;
}

form {
    display: flex;
   flex-direction: column;
}

.inp {
    background-color: #EDF0F2;
    border: none;
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 1vh;
    padding: 1vh;
}

.inp.mobile {
    background-color: #EDF0F2;
    border: none;
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 1vh;
    padding: 1vh;
    font-size: 1.5vh;
}


.btn {
    border: none;
    background-color: #475059;
    color: white;
    border-radius: 5px;
    width: 8vw;
    padding: 0.8vh;
    align-self: center;
    margin-top: 2vh;
}

.btn.mobile{
    align-self: center;
    background-color: #475059;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 1.2vh;
    width: 20vw;
    font-size: 1.5vh;
}

.forgotPassword {
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 5px;
}

.link {
    text-decoration: none;
    color: blue;
    font-size: 1.2vh;
    margin: 5px;
}

.login {
    align-self: center;
}

.error {
    color: red;
}

.signup {
    text-align: center;
    font-size: 1.1vh;
    margin-top: 0.8vh;
    color: slategray;
}