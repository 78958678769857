.navbar {
    background-color: rgb(50, 65, 63);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.6vh;
    padding-top: 2.8vh;
    gap: 2rem;
    width: 100%;
    border-bottom-left-radius: 50px;
    position: sticky;
    top: 0;
  }

  .navbar.mobile{
    background-color: rgb(50, 65, 63)!important;
    color: white!important;
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    padding-top: 2.8vh!important;
    gap: 2rem!important;
    width: 100%!important;
    border-radius:0px !important;
    height: 6vh!important;
  }

.image {
    height: 3.5vh;
    margin-right: 1vw;
    margin-top: 0vh;
    margin-left: 6vw;
    margin-bottom: 2.9vh;
}

.container {
    display: flex;
    align-items: center;
    width: 15vw;
    margin-bottom: 2.9vh;
}

.container.mobile {
    display: flex!important;
    align-items: center!important;
    margin-bottom: 2.9vh!important;
    width: auto!important;
    margin-right: 4vw!important;
}

.container a {
    font-size: clamp(10px, calc(10px + (15 - 10) * ((100vw - 1366px) / (1920 - 1366))), 15px);
    color: white;
    font-weight: 500;
    text-decoration: none;
    margin-right: 2vw;
}

.user {
    height: 3.5vh;
    width: 3.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(75, 64, 59, 0.5);
    border-radius: 50%;
}

.userico {
  height: 1.5vh;
}

.logout {
    margin-right: 10px;
}

