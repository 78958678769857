.container {
    display: flex;
    border-radius: 12px;
    background: transparent;
    height: 83%;
    width: 31.6%;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    overflow: visible;
}

.fondo{
    display: flex;
    border-radius: 12px;
    align-items: center;
    background: #f8f4f3;
    height: 100%;
    width: 60%;
    position: relative;
    overflow: visible;
}
.image {
    height: auto;
    width: 80%;
    position: absolute;
    left: -25%;
    z-index: 2;
}

.fondo h1{
    color: #344955;
    font-size: calc(8px + (14px - 8px) * 0.5);
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    position: absolute;
    left: 54%;
    bottom: 10%;
    z-index: 2;
}

@media screen and (max-width: 450px) {

    .container{
        background-color: transparent;
    }

    .fondo{ 
        background-color: transparent;
        width: auto;
    }

    .image{
        width: 95%;
        position: relative;
        left: 0px;
    }

    .fondo h1{
        font-size: 4px;
        left: 67%;
        bottom: 10%;
    }
}