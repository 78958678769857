.screen {
    font-family: 'M PLUS 1', sans-serif;
    overflow-y: auto; /* Habilita el scroll vertical */
    overflow-x: hidden;
    
}

header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.container {
    background: #EDF0F2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.cardsContainer {
    padding: 0px 10.68vw 0px 11.2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardsContainerGraf{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    align-items: center ;
    align-content: center;
}

.Graf {
    width: 90%;
}

.Graf.mobile {
    width: 90%;
    height: 40vh;
    margin-bottom: 2vh;
}


.blenderCards {
    display: flex;
    flex-direction: row;
}

.contentBody {
    height: 44.6vh;
    width: auto;
    display: flex;
    background-color: #f8f4f3;
    margin: 2.78vh 10.68vw 0px 11.2vw;
    margin-top: 2.78vh;
    border-radius: 20px;
    justify-content: center;
    padding: 4vh 0vw;
}

.lineH {
    height: 1px;
    width: 66.67vw;
    background-color: rgba(217, 217, 217, 0.5);
    margin-left: 16.51vw;
}

.contentchildIzq{
    height: 51.85vh;
    width: 43.6vw;
    margin: 2.78vh;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    
}

.lineV {
    height: 50vh;
    width: 1px;
    background-color: rgba(217, 217, 217, 0.5);
}

.contentchilTop{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.85vw;
    
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filtersContent {
    display: flex;
    gap: 33px;
    margin: 0vh 0px 5vh 3vw;
    justify-content: space-around;
}

.filtersContent label{
    font-size: 10px;
}

.filtersFiler {
    width: 7vw;
    height: 10vh;
    border: 1px solid lightgray;
    padding: 0vh 1vw;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.filtersFiler input{
    font-size: 10px;
    background: transparent;
    border-width: 0px;
    padding: 0px;
}

.FilterPanel {
    align-self: center;
}