.filtersContent {
    display: flex;

    justify-content: space-around;

    flex-direction: column;
}

.filtersContent label{
    font-size: 10px;
}

.filtersContent.mobile label{
    font-size: 1vh;
    margin: 0.2vh;
}

.filtersFiler {
    width: 6vw;
    height: 10vh;
    border: 1px solid lightgray;
    padding: 0vh 1vw;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    
}

.filtersFiler.mobile{
    width: 18vw;
    border: 1px solid lightgray;
    padding: 0vh 1vw;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0px;
    margin-bottom: 1vh;
}

.filtersFiler input{
    font-size: 10px;
    background: transparent;
    border-width: 0px;
    padding: 0px;
    
}

.filtersFiler.mobile input{
    font-size: 1vh;
    background: transparent;
    border-width: 0px;
    padding: 0px;
    width: 18vw;
}