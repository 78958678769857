.container h2 {
    color: #344955;
    font-size: clamp(9px, calc(9px + (14 - 9) * ((100vw - 1366px) / (1920 - 1366))), 14px);
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    padding: 0px 0px 0px 1.6vw;
    text-align: justify;
    margin-bottom: 0px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.78vh;
    border-radius: 12px;
    background: #f8f4f3;
    height: 11.6vh;
    width: 14.61vw;
    justify-content: center;
}

.dataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

