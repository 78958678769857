.container {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #f8f4f3;
    height: 100%;
    width: 34.55vw;
    justify-content: flex-start;
    padding-left: 2.85vw;
    padding-top: 5.55vh;
}



.rec {
    width: 1.5vw;
    height: 3px;
    background: #d3cfce;
    border-radius: 1px;
    margin: 2vh 0px 2.7vh 0px;
}

.containerchild{
    display: flex;
    justify-content: space-between;
    width:  80%;
}

.container h1 {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    margin: 0px;
    font-weight: 600;
}