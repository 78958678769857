.screen {
    font-family: 'M PLUS 1', sans-serif;
    overflow-y: auto;
    max-height: 100vh;
}

html, body {
    overflow: auto;
  }


h3 {
    border-radius: 8px 8px 0px 0px;
    background: #344955;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h4 {
    color: #90A4AE;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.container {
    background: #EDF0F2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.cardsContainer {
    padding: 0px 10.68vw 0px 11.2vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blenderCards {
    display: flex;
    flex-direction: row;
}

.contentBody {
    height: 54.6vh;
    width: auto;
    display: flex;
    padding: 0px 10.68vw 0px 11.2vw;
}

.lineH {
    height: 1px;
    width: 66.67vw;
    background-color: rgba(217, 217, 217, 0.5);
    margin-left: 16.51vw;
}

.contentchildIzq{
    height: 51.85vh;
    width: 43.6vw;
    margin: 2.78vh;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    
}

.lineV {
    height: 50vh;
    width: 1px;
    background-color: rgba(217, 217, 217, 0.5);
}

.contentchilTop{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.85vw;
    
}

.mapView {
    height: 100vh;
    width: 100%;
    display: flex;  
}

.mapContainer {
    display: flex;
    justify-content: center;
    padding: 0vh 10.68vw 0px 10.68vw;
    height: 66vh;
    align-items: flex-start;
}

.margen {
    height: 5vh;
    width: 100%;
}