.navbar {
  align-items: center;
  background-color: #32413f;
  color: #fff;
  display: flex;
  gap: 2rem;
  height: 7.4vh;
  justify-content: space-between;
  width: 100%;
}

.navbar a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.img {
    height: 8.5vh;
    margin-left: 6vw;
    margin-top: 1vh;
}
  
.navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    height: 100%;
}

.navbar li {
    cursor: pointer;
    padding: 5;
    display: flex;   
}
  
.navbar li a {
    align-self: center;
    font-size: 1.8vh;
    margin-top: 0.5vh;
}

  
.navbar  .active {
    border-bottom: 3px solid #ffffff;
    width: 80%;
    border-radius: 1.5px;
    padding-top: 3px;
  }

  

.link {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }