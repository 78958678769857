html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto; /* Habilita el scroll vertical */
    overflow-x: hidden;
    touch-action: manipulation; /* Evita el zoom con gestos en pantallas táctiles */
    overscroll-behavior: none; /* Previene efectos de desplazamiento no deseados */
   
}

h2 {
    color: #fff; 
    padding: 20px
}

h3 {
    border-radius: 8px 8px 0px 0px;
    background: #344955;
    color: #FFF;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h4 {
    color: #90A4AE;
    text-align: center;

    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
}

.cardsContainer {
    padding: 0px 2.32vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blenderCards {
    display: flex;
    flex-direction: row;
}