.navbar {
  background: #32413F;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.8vh;
  gap: 2rem;
  padding: 0px 4vw;
}

.navbar a {
  color: inherit;
  text-decoration: none;
  height: auto;
  cursor: pointer;
}

.navbarselect {
  font-size: 1.5vh;
}

.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  height: 100%;
  width: 8vw;
  justify-content: space-around;
}
.navbar li {
  cursor: pointer;
  display: flex;
  
}
.navbar li a {
  align-self: center;
}


.navbar  .active {
  border-bottom: 3px solid #ffffff;
  border-radius: 1px;
  padding-top: 3px;
}

.tabIndicator {
  height: 2px;
  background-color: white;
  width: 50%;
  transition: left 0.3s ease; /* Agrega transición para un efecto suave */
}

.logout {
  padding-right: 10px;
  font-size: 1.6vh;
}

.user {
  height: 3.5vh;
  width: 3.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(75, 64, 59, 0.5);
  border-radius: 50%;
}

.userico {
height: 1.5vh;
}

.logout {
  margin-right: 10px;
}

@media screen and (max-width: 450px) {

  .navbar {
    background: #32413F;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
    gap: 2rem;
    padding: 0px 5vw;
    margin-bottom: 0px;
  }

  .navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
    height: 100%;
    width: 19vw;
    justify-content: space-around;
  }

  .navbarselect {
    font-size: 1.2vh;
  }

  .navbar li a {
    align-self: center;
    margin-top: 1vh;
  } 

  .logout {
    margin-right: 0px;
  }

}