.container h2 {
    color: #344955;font-style: normal;
    font-weight: normal;
    line-height: normal;
    padding: 0px 0px 0px 1.6vw;
    text-align: justify;
    font-size: clamp(6px, calc(6px + (8 - 6) * ((100vh - 660px) / (930 - 660))), 8px);
    padding-left: 3vw;
    margin-bottom: 0px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 31.6%;
    justify-content: center;
    border-radius: 5px;
    height: 85%;
    
}

.containerchild {
    display: flex;
    flex-direction: row;
    padding: 0px 0px 0px 1.3vw;
    padding-left: 3vw
}

.value {
    color: #344955;
    font-size: clamp(16px, calc(16px + (20 - 16) * ((100vh - 660px) / (930 - 660))), 20px);
    font-weight: normal;
    line-height: normal;
    text-align: justify;
    align-self: baseline;
}

.unity {
    color: #344955;
    font-size: 6px;
    font-weight: 400;
    line-height: normal;
    align-self: flex-end;
    align-self: baseline;
    padding-left: 0.9375vw;
}


