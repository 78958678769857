.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 2vw;
    align-items: baseline;
    height: 7vh;
    align-content: center;
}

.container h1 {
    padding-right: 3.4vw;
    color: #17262A;
    align-self: baseline;
    font-weight: 400;
    font-size: 2vh;
    margin: 0px;
}

.container h5 {
    color: #90A4AE;
    font-size: 1.5vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 3.125vw;
    margin: 0px;
}

.container h6 {
    color: #90A4AE;
    font-size: 1.2vh;
    font-style: normal;
    font-weight: 700;
    padding-right: 0.78vw;
    margin: 0px;
}

@media screen and (max-width: 450px) {
    .container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding-left: 5vw;
        align-items: baseline;
        height: 8vh;
        align-content: flex-end;
        
    margin-bottom: 2vh;
    }

}