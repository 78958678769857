.container h2 {
    color: #344955;
    font-size: clamp(14px, calc(14px + (20 - 14) * ((100vw - 1366px) / (1920 - 1366))), 20px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 0px 0px 1.3vw;
    text-align: justify;
    margin: 0px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.10);
    width: 18vw;
    height: 12vh;
    justify-content: center;
    min-width: 230px;

}

.dataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media screen and (max-width: 1440px) {
    h2 {
        font-size: 16px;
    }

}

@media screen and (max-width: 450px) {
    .container h2 {
        color: #344955;
        font-size: clamp(6px, calc(1.11111px + .74074vh), 8px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0px 0px 0px 1.3vw;
        text-align: justify;
        margin: 0px;
    }
    
    .container {
        display: flex;
        flex-direction: column;
        margin-bottom: 2vh;
        border-radius: 8px;
        background: #FFFFFF!important;
        box-shadow: 4px 4px 20px 0 #0000001a;
        width: 31%;
        height: 8vh;
        justify-content: center;
        min-width: 20px;
    
    }
    
    .dataContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

}