body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
    font-size: 1.17em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: bold;
}

h5 {
  color: #90A4AE;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 3.125vw;
}

h6 {
  color: #90A4AE;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-right: 0.78vw;
  align-self: baseline;
}

p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 16px;
  }

  h6 {
    font-size: 10px;
  }
  h5{
    font-size: 12px;
  }

  p{
    font-size: 15px;
  }

  
  
}