.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 0px 0px 1.3vw;
    height: 4.62vh;
}

.value {
    color: #344955;
    font-size: clamp(26px, min(calc(26px + (36 - 26) * ((100vw - 1366px) / (1920 - 1366))), calc(26px + (36 - 26) * ((100vh - 768px) / (1080 - 768)))), 36px);
    font-weight: normal;
    line-height: normal;
    text-align: justify;
    align-self: baseline;
}

.unity {
    color: #344955;
    font-size: clamp(8px, min(calc(10px + (12 - 10) * ((100vw - 1366px) / (1920 - 1366))), calc(10px + (12 - 10) * ((100vh - 768px) / (1080 - 768)))), 12px);
    font-weight: 400;
    line-height: normal;
    align-self: flex-end;
    align-self: baseline;
    padding-left: 0.9375vw;
}

