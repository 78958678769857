.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: .5vw;
    height: 50px;
}

.poweredBy {
    color: #B2B3B4;
    font-size: 12px;
font-weight: bold;

align-self: center;
}

.image {
    /* max-width: 6vw;
    max-height: 10vh; */
    width: 50px;
    height: 50px;
    align-self: center;
}

@media screen and (max-width: 450px) {

    .container {
        height: 3vh;
    }
    .poweredBy {
        font-size: 8px;
    }

    .image {
        width: 30px;
        height: 32px;
    }


}