.container {
    display: flex;
    height: 100%;
    width: 49%;
    justify-content: center;
    padding-left: 8%;
    border-radius: 7px;
    flex-direction: row;
}


.rec {
    background: #d3cfce;
    border-radius: 1px;
    width: 3.5vw;
    height: 2px;
    margin: 2.5vh 0px 0px 0px;
}

.containerchild{
    display: flex;
    width:  90%;
    flex-direction: column;
        margin-left: 8vw;
        margin-bottom: 2.5vh;
        justify-content: flex-end;
}

.container h1 {
    margin: 0px;
    font-weight: 600;
    font-size: 7px;
    margin-top: 3vh; 

}


