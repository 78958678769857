.container {
    display: flex;
    border-radius: 12px;
    background: #f8f4f3;
    height: 23.5vh;
    width: 17.18vw;
    align-items: center;
    justify-content: center;
}

.image {
    height: 20.4vh;
    width: 21.9vw;
    margin-right: 7vw;
    margin-top: 2vh
}

@media screen and (max-width: 1440px) {
    h2 {
        font-size: 16px;
    }

}