.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 4vh;
    font-family: 'M PLUS 1', sans-serif;
    background: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width:max-content;
    padding: 10px;
}

.container.mobile {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 4vh;
    font-family: 'M PLUS 1', sans-serif;
    background: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: max-content;
    padding: 10px;
}

.filterPanelContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-evenly;
    margin-bottom: 20px;
    
}

.filterPanelContainer.mobile {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-evenly;
    margin-bottom: 1vh;
    
}

.filterPanelChill{
    display: flex;
    flex-direction: column;
    
}

.filterPanel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    max-width: 400px;
    justify-content: space-between;
}

.filterPanel.mobile{
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px;
}

label {
    font-size: 1.2vh;
    margin-bottom: 0.7vh;
}

.filterOptions {
    font-size: 1.2vh;
}

.filterOptions.mobile {
    padding: 0.5vh;
    border-radius: 5px;
    margin-bottom: 1vh;
}

select, button {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
}



.btn {
    background:rgb(50, 65, 63);
    color: white;
    cursor: pointer;
    border: none;
    max-width: 200px;
}

.btn.mobile {
    background:rgb(50, 65, 63);
    color: white;
    cursor: pointer;
    border: none;
    max-width: 200px;
    font-size: 1.2vh;
    padding: 1vh;
    width: 22vw;
    border-radius: 4px;
}

button:hover {
    background: #0056b3;
}

.containerBtn{
    display: flex;
    width: 22vw;
    justify-content: space-evenly;
}