.container {
    height: 46.3vh;
    width: 30vw;
    border-radius: 12px;
    background: #f8f4f3;
    margin: 2.78vh 0px 0px 2.78vh;
    padding-left: 2.85vw;
    padding-top: 5.55vh;
    

}

.rec {
    width: 1.5vw;
    height: 3px;
    background: #d3cfce;
    border-radius: 1px;
    margin: 2vh 0px 2.7vh 0px;
}

.containerchild{
    display: flex;
    justify-content: space-between;
    width:  60%;
}

.container h1 {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    margin: 0px;
    font-weight: 600;
}

.containerLub {
    margin-top: 8vh;
    margin-left: 1vw;
}

.containerLub a {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    font-weight: 500;
    color: #585858;
    margin-bottom: 3.7vh;
}

.containerLubchild {
    display: flex;
    justify-content: space-between;
    margin-top: 4vh;
    width: 75%;
}

.containerLubchild2 {
    display: flex;
    justify-content: space-between;
    
}

.recLub {
    height: 1px;
    width: 1vw;
    margin-top: 1vh;
    background-color: #d9d9d9;
 }

