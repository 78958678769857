.body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 95%;
    width: 100%;
    margin: 2.78vh 0px;
}

.body.mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 0px;
}

.contain1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 17%;
    width: 100%;
}

.contain1.mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 15%;
    width: 100%;
}

.contain2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 28%;
    width: 100%;
}

.contain2.mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 25vh;
    width: 100%;
}

.margen{
    height: 2vh;
    width: 100%;
}

