.container {
    display: flex;
    flex-direction: column;
    margin-left: 1vw;

}



.container h1 {
    font-size: clamp(10px, calc(10px + (16 - 10) * ((100vw - 1366px) / (1920 - 1366))), 16px);
    font-weight: 600;
    color: #aaaaaa;

}

.containerchild{
    display: flex;
    align-items: baseline;
}

.containerchild h3{
    font-size: clamp(8px, calc(8px + (10 - 8) * ((100vw - 1366px) / (1920 - 1366))), 10px);
    color: #737373;
    font-size: 600;
    background: transparent;
    margin-left: 5px;
}

.container h2 {
    font-size: clamp(12px, calc(12px + (20 - 12) * ((100vw - 1366px) / (1920 - 1366))), 20px);
    font-weight: 600;
    color: #525252;
    margin-top: 1vh;
    padding: 0px;
}
