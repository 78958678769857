.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 7px;
        justify-content: space-between;
        padding-left: 8%;
        width: 31%;
        margin : 0px;
}

.rec {
    background: #d3cfce;
    border-radius: 1px;
    width: 3.5vw;
    margin:2.5vh 0px 0px 0px;
    height: 2px;
}

.containerchild{
    display: flex;
    justify-content: space-between;
    width:  64%;
    flex-direction: column;
    margin-bottom: 2.5vh;
    margin-left: 6%;
}

.title {
    margin: 0px;
    font-weight: 600;
    font-size: 7px;
    margin-top: 3vh;
}

